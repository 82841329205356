import { useQuery } from '@tanstack/react-query'
import { requestGET } from '../services/request'
import { useOrganizationView } from '../components/organizationView/useOrganizationView'
import { useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export const useOrganizations = () => {
  return useQuery([`organizationsQuery`], async () =>
    requestGET(`organization`),
  )
}

export const useIsHeartEyeOrganization = (): boolean => {
  const { user } = useAuth0()
  const orgName: string | null = useMemo(
    () => user?.[`${process.env.GATSBY_AUTH_NAMESPACE}/org_name`],
    [user],
  )
  return /hearteye/i.test(orgName || ``)
}

export const useIsTopLevelOrganization = (): boolean => {
  const { orgData } = useOrganizationView()
  return !orgData?.parentId
}

export const useIsAssessorOrganization = (): boolean => {
  const { orgData } = useOrganizationView()
  const isHearteyeTenant = useIsHeartEyeOrganization()
  const isTopLevelOrganization = useIsTopLevelOrganization()

  return (
    !!(
      !Array.isArray(orgData?.assessorOrgIds) ||
      orgData?.assessorOrgIds.length === 0
    ) &&
    isHearteyeTenant &&
    !isTopLevelOrganization
  )
}

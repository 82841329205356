import { createTheme } from '@mantine/core'

const brandColors: readonly [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  ...string[],
] = [
  `#fff9e0`,
  `#fff1ca`,
  `#ffe199`,
  `#ffd162`,
  `#ffc336`,
  `#ffba18`,
  `#ffb502`,
  `#e49f00`,
  `#ca8d00`,
  `#af7800`,
]

export const theme = createTheme({
  fontFamily: `Nunito Sans, sans-serif`,
  breakpoints: {
    xs: `30em`,
    sm: `48em`,
    md: `64em`,
    lg: `74em`,
    xl: `90em`,
  },
  headings: {
    fontFamily: `Nunito Sans, sans-serif`,
  },
  colors: {
    brand: brandColors,
  },
  primaryColor: `brand`,
  primaryShade: 7,

  components: {
    Button: {
      styles: () => ({
        root: {
          borderRadius: 1000,
          // fontWeight: 500,
          // color: BLACK_COLOR,
        },
      }),
    },
    Table: {
      styles: () => ({
        thead: {
          backgroundColor: brandColors[2],
        },
      }),
    },
  },
})

import { MantineProvider } from '@mantine/core'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { navigate } from 'gatsby'
import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { Notifications } from '@mantine/notifications'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Auth0Context, Auth0Provider } from '@auth0/auth0-react'
import { theme } from '../theme'
import { useDisclosure } from '@mantine/hooks'
import { MantineEmotionProvider } from '@mantine/emotion'

const onRedirectCallback = (appState: any) => {
  navigate(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname,
    { replace: true },
  )
}

const audience = process.env.GATSBY_AUTH0_AUDIENCE

const deferred = (() => {
  const props: any = {}
  props.promise = new Promise((resolve) => (props.resolve = resolve))
  return props
})()

export const getAccessToken = async () => {
  const getToken = await deferred.promise
  return getToken()
}

const AuthContextConsumer = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently } = useContext(Auth0Context)

  deferred.resolve(getAccessTokenSilently)

  return children
}

export const Providers: FC<any> = ({ children }: { children: ReactNode }) => {
  const queryClient = new QueryClient()

  // useEffect(() => {
  //   if (window.location.pathname === `/`) {
  //     navigate(`/patients`)
  //   }
  // }, [])

  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN || ``}
      clientId={process.env.GATSBY_AUTH0_CLIENTID || ``}
      authorizationParams={{
        redirect_uri: process.env.GATSBY_AUTH0_CALLBACK || ``,
        audience,
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      <MantineEmotionProvider>
        <MantineProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AuthContextConsumer>{children}</AuthContextConsumer>
          </QueryClientProvider>
        </MantineProvider>
      </MantineEmotionProvider>
    </Auth0Provider>
  )
}

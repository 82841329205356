import React, { useState, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Tooltip,
  ActionIcon,
  Popover,
  SegmentedControl,
  Indicator,
} from '@mantine/core'
import { IconLanguage } from '@tabler/icons-react'
import * as classes from './languageDropdown.module.css'

const LanguageDropdown = () => {
  const { t, i18n } = useTranslation()
  const [currentLang, setCurrentLang] = useState(i18n.language)
  const languageNames =
    !!currentLang &&
    new Intl.DisplayNames([currentLang], {
      type: `language`,
    })
  const data = [
    {
      image: `🇬🇧`,
      label: `🇬🇧 ` + (!!languageNames && languageNames.of(`en`)),
      value: `en-GB`,
    },

    {
      image: `🇳🇱`,
      label: `🇳🇱 ` + (!!languageNames && languageNames.of(`nl`)),
      value: `nl-NL`,
    },
    {
      image: `🇩🇪`,
      label: `🇩🇪 ` + (!!languageNames && languageNames.of(`de`)),
      value: `de-DE`,
    },
  ]

  const currentFlag =
    !!currentLang && data.find((item) => item.value === currentLang)?.image

  const onLanguageSwitch = (language: string) => {
    setCurrentLang(language)
    i18n.changeLanguage(language)
  }

  return (
    <Popover shadow="md">
      <Indicator label={currentFlag} className={classes.indicator} size={30}>
        <Popover.Target>
          <Tooltip label={t(`global.language`)}>
            <ActionIcon variant="default" size="md">
              <IconLanguage />
            </ActionIcon>
          </Tooltip>
        </Popover.Target>
      </Indicator>
      <Popover.Dropdown p={0}>
        <SegmentedControl
          data={data}
          value={currentLang}
          onChange={onLanguageSwitch}
          orientation="vertical"
        />
      </Popover.Dropdown>
    </Popover>
  )
}

export default LanguageDropdown

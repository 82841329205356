import { AppShell } from '@mantine/core'
import { type PageProps } from 'gatsby'
import React, { FC, useState } from 'react'

import SideMenu from './sideMenu'
import TopNavbar from './topnavbar'
import { Notifications } from '@mantine/notifications'

const Layout: FC<any> = ({ children }: PageProps) => {
  const [drawerState, setDrawerState] = useState(false)

  return (
    <AppShell
      padding="md"
      navbar={{
        width: 140,
        breakpoint: 0,
        collapsed: { mobile: false },
      }}
      header={{ height: 52 }}
    >
      <TopNavbar drawerState={drawerState} toggleDrawer={setDrawerState} />
      <SideMenu />
      <AppShell.Main>{children}</AppShell.Main>
      <Notifications position={`bottom-right`} />
    </AppShell>
  )
}

export default Layout

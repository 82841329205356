import { useQuery } from '@tanstack/react-query'
import { requestGET } from '../services/request'

export const useUser = (enabled = false) =>
  useQuery<any>({
    queryKey: [`user`],
    queryFn: async () => {
      const response = await requestGET(`/user`)
      return response
    },
    enabled,
  })

export const useM2MToken = () =>
  useQuery<any>({
    queryKey: [`adminToken`],
    queryFn: async () => {
      return (
        await requestGET(`organization/m2mTokenFromAdminToken`)
      ).replaceAll(`"`, ``)
    },
  })

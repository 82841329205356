import urlJoin from 'url-join'
import secureLocalStorage from 'react-secure-storage'
import { LogoutOptions } from '@auth0/auth0-spa-js'
import axios, { AxiosRequestConfig } from 'axios'
import { getAccessToken } from '../components/providers'

const GATSBY_API_URL = process.env.GATSBY_API_URL || ``
let logout: (_?: LogoutOptions) => Promise<void>

export function setLogout(fn: (_?: LogoutOptions) => Promise<void>) {
  logout = fn
}

export const request = async (path: string, init?: RequestInit) => {
  const accessToken = await getAccessToken()

  const initWithHeaders = {
    ...{
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    },
    ...(init || {}),
  }

  const preparedUrl = path.includes(`http`)
    ? path
    : urlJoin(GATSBY_API_URL, path)

  const response = await fetch(preparedUrl, initWithHeaders)

  // if (response.status === 401) {
  //   logout && (await logout());
  //   return;
  // }

  const contentType = response.headers.get(`Content-Type`)

  let data
  if (contentType && contentType.includes(`application/json`)) {
    data = await response.json()
  } else if (contentType && contentType.includes(`text`)) {
    data = await response.text()
  } else {
    data = await response.blob()
  }

  if (!response.ok) {
    throw data
  }

  return data
}

export const requestGET = (path: string, init?: RequestInit) => {
  const initGet = { ...(init || {}), ...{ method: `GET` } }
  return request(path, initGet)
}

export const requestPOST = (path: string, init?: RequestInit) => {
  const initPost = { ...(init || {}), ...{ method: `POST` } }
  return request(path, initPost)
}

export const requestPATCH = (path: string, init?: RequestInit) => {
  const initPatch = { ...(init || {}), ...{ method: `PATCH` } }
  return request(path, initPatch)
}

export const requestDELETE = (path: string, init?: RequestInit) => {
  const initPatch = { ...(init || {}), ...{ method: `DELETE` } }
  return request(path, initPatch)
}

export const uploadEegWithProgress = async (
  path: string,
  options: AxiosRequestConfig = {},
) => {
  const accessToken = await getAccessToken()

  const preparedUrl = path.includes(`http`)
    ? path
    : urlJoin(GATSBY_API_URL, path)

  const response = await axios.request({
    url: preparedUrl,
    headers: {
      'Content-Type': `multipart/form-data`,
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': `*`,
    },
    ...options,
  })

  if (response.status === 401) {
    logout && (await logout())
    return
  }

  const data = await response

  return data
}
